<template>
  <div>
    <!-- <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div> -->
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-groupe-emc.svg"
                  alt
                  height="150"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo-groupe-emc-inline.svg"
                  alt
                  height="150"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Connexion</h5>
                  <p class="text-muted">Connectez-vous pour accéder à la plateforme.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >
                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="user.email"
                        type="text"
                        placeholder="Entrer un email"
                        :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >Veuillez entrer une adresse email.</span
                        >
                        <span v-if="!$v.user.email.email"
                          >Veuillez entrer une adresse valide.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <label for="input-2">Mot de passe</label>
                      <b-form-input
                        id="input-2"
                        v-model="user.password"
                        type="password"
                        placeholder="Entrer le mot de passe"
                        :class="{
                          'is-invalid': submitted && $v.user.password.$error,
                        }"
                      ></b-form-input>
                      <div class="float-end">
                        <router-link
                          to="/forgot-password"
                          class="text-muted"
                          >Mot de passe oublié ?</router-link
                        >
                      </div>
                      <div
                        v-if="submitted && !$v.user.password.required"
                        class="invalid-feedback"
                      >
                        Veuillez entrer votre mot de passe.
                      </div>
                    </b-form-group>
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm"
                        >Se connecter</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Groupe EMC
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Auth from '../../../models/auth';

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      user: new Auth('', '')
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
  computed: {
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          if (this.user.email && this.user.password) {
            this.$store.dispatch("auth/login", this.user);
          }
        }
      }
    },
};
</script>

<style lang="scss" module></style>
